import * as React from "react"

const Banner = () => {
  return (
    <div className="bg-gradient-to-r from-blue-800 via-blue-600 to-blue-500">
      <div className="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto">
        {/* Grid */}
        <div className="grid justify-center md:grid-cols-2 md:justify-between md:items-center gap-2">
          <div className="text-center md:text-left">
            <p className="text-xs text-white/[.8] uppercase tracking-wider">
              Try G10bal today
            </p>
            <p className="mt-1 text-white font-medium">
              See how we can streamline your payments, reconciliation & reporting
            </p>
          </div>
          {/* End Col */}
          <div className="mt-3 text-center md:text-left md:flex md:justify-end md:items-center">
            <a
              className="py-3 px-6 inline-flex justify-center items-center gap-2 rounded-full font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm"
              href="/#pricing-section"
            >
              Get started
              <svg
                  className="flex-shrink-0 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
            </a>
          </div>
          {/* End Col */}
        </div>
        {/* End Grid */}
      </div>
    </div>
  );
}

export default Banner;
